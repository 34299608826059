import styles from './index.module.less';
import { useEffect, useState } from 'react';

const documentList = [
    {
        title: "领域驱动设计漫谈",
        url: "./blogs/lingyuqudongsheji.htm",
        overview: "摘要： DDD简介 领域驱动设计（Domain Driven Design），软件核心复杂性应对之道。 Fred Brooks 在经典著作《人月神话》中对于软件复杂度有着精彩的论述，他将软件复杂度分为本质复杂度（Essential Complexity）和偶然复杂度（Accidental Complexit）"
    },
    {
        title: "Mybatis3 Dynamic Sql实践",
        url: "./blogs/mybatis3DynamicSql.htm",
        overview: "摘要： 背景 最近在做项目的时候，需要用到多表关联查询，关联的表和查询的条件都是不确定的，且可能会有非常复杂的查询场景，导致查询条件会很复杂，在这种场景下，sql模版是不确定的，所以传统的MyBatis3风格(即经常用的xml风格)或者MyBatis3Simple风格的sql模版框架就显得力不从心"
    },
    {
        title: "Java实现锁、公平锁、读写锁、信号量、阻塞队列、线程池等常用并发工具",
        url: "./blogs/javatools.htm",
        overview: "摘要： 锁的实现 锁的实现其实很简单，主要使用Java中synchronized关键字。 公平锁的实现 上面的锁的实现严格意义上说是会存在线程饥饿现象的（也就是说在多线程竞争的条件下，存在一种极端情况，即某个线程一直阻塞在锁上，永远都是其他线程被优先唤醒，导致自己得不到执行）。",
    },
    {
        title: "自己动手实现一个轻量级的RPC框架",
        url: "./blogs/zijishixianrpc.htm",
        overview: "摘要： 前言 最近学习RPC的时候，自己手动写了一个简易的RPC框架，以下是这个项目的README： 简介 Java语言编写的一个轻量级的RPC框架，基于TCP协议，与SpringBoot集成，让远程调用像本地调用",
    }
]

export default function IcpBeian() {

    const [showIframePopup, setShowIframePopup] = useState(false)
    const [iframeTitle, setIframeTitle] = useState('')
    const [iframeUrl, setIframeUrl] = useState('')

    const getBeianDescByDomain = () => {
        const hostname = window.location.hostname
        if(hostname.indexOf('jeysin.top') >= 0) {
            return "浙ICP备2022033703号";
        } else if(hostname.indexOf('onewish.cn') >= 0) {
            return "苏ICP备2023030095号-1";
        } else if(hostname.indexOf('wittyai.cn') >= 0) {
            return "苏ICP备2024070402号-1";
        } else if(hostname.indexOf('bytesea.site') >= 0) {
            return "苏ICP备2023030095号-11";
        } else {
            return "浙ICP备2022033703号"
        }
    }

    const getTitleByDomain = () => {
        const hostname = window.location.hostname
        if(hostname.indexOf('jeysin.top') >= 0) {
            return "杭州燕景网络科技有限公司";
        } else if(hostname.indexOf('onewish.cn') >= 0) {
            return "南京瑜亮网络科技有限公司";
        } else if(hostname.indexOf('wittyai.cn') >= 0) {
            return "南京网景微讯网络科技有限公司";
        } else if(hostname.indexOf('bytesea.site') >= 0) {
            return "南京瑜亮网络科技有限公司";
        } else {
            return "杭州燕景网络科技有限公司"
        }
    }

    useEffect(() => {
        document.title = getTitleByDomain();
    }, []);

    const handleClick = (doc) => {
        openIframePopup(doc.title, doc.url)
    }

    const openIframePopup = (title, url) => {
        setShowIframePopup(true)
        setIframeTitle(title)
        setIframeUrl(url)
    }

    const closeIframePopup = () => {
        setShowIframePopup(false)
    }

    return (
        <div>
            <div className={styles.title}>
                <h1>{getTitleByDomain()}技术文章</h1>
                <div>面向信仰编程</div>
            </div>
            <div className={styles.content}>
                {documentList.filter(doc => doc.title).map(doc => (
                    <div key={doc.url} onClick={() => handleClick(doc)} className={styles.documentItem}>
                        <div className={styles.contentItem}>
                            <div>{doc.title}</div>
                        </div>
                        <div className={styles.contentOverview}>{doc.overview}</div>
                    </div>
                ))}
            </div>
            <div className={styles.beian}>
                <a href="https://beian.miit.gov.cn">{getBeianDescByDomain()}</a>
            </div>
            {showIframePopup && (
                <div className={styles.overlayStyle} onClick={closeIframePopup}>
                    <div className={styles.popupStyle}>
                        <button className={styles.closeButtonStyle} onClick={closeIframePopup}>关闭</button>
                        <iframe
                            src={iframeUrl}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            title={iframeTitle}/>
                    </div>
                </div>
            )}
        </div>
    )
}