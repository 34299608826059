import {useState, useEffect} from 'react';
import styles from './index.module.less';
import { useNavigate } from 'react-router-dom';
import {ActionSheet, Image, NoticeBar, Toast, Modal, Popup, TextArea, Button} from 'antd-mobile';
import BottleTabbar from '../../components/bottle-tabbar';
import homeImage from '../../images/home.png';
import homeActiveImage from '../../images/home-active.png';
import messageImage from '../../images/message.png';
import messageActiveImage from '../../images/message-active2.png';
import filterImage from '../../images/filter.png';
import bottleImage from '../../images/bottle.png';
import netImage from '../../images/net.png';

const icons = [
    {
        image: homeImage,
        activeImage: homeActiveImage,
        name: '漂流瓶'
    },{
        image: messageImage,
        activeImage: messageActiveImage,
        name: '消息'
    }
]

const actions = [
    { 
        text: '只看女生', 
        key: 'woman'
    },{ 
        text: '只看男生', 
        key: 'man' 
    }
]

const modalActions = [
    {
      key: 'chat',
      text: '私信TA',
      primary: true,
    }
 ]

const noticeText = '1、为建设文明和谐的交友环境，平台已加大审核力度，严厉打击低俗色情内容和欺诈诱导行为，多次发布违规信息的用户将被系统自动封禁，屡教不改者将永久封禁！2、我们发现平台上存在裸聊诈骗人员，目前已经封禁其账号，但不排除还有漏网之鱼，请大家不要轻易下载不明软件，以防被骗子盗取手机通讯录进行裸聊诱骗后敲诈勒索。';

export default function BottleHomepage() {
    const navigate = useNavigate();
    const [filterDesc, setFilterDesc] = useState('只看女生');
    const [visible, setVisible] = useState(false)
    const [salvageDesc, setSalvageDesc] = useState(1);
    const [throwDesc, setThrowDesc] = useState(1);
    const [popupVisible, setPopupVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);

    const onClickIcon = (index) => {
        if(index === 1) {
            //跳转到bottleMessage
            navigate('/h5/bottle-message')
        }
    }

    const onClickFilterButton = () => {
        setVisible(true)
    }

    const onActionSheetClick = (action) => {
        setFilterDesc(action.text)
        setVisible(false)
    }

    const onClickThrowBottle = () => {
        if(throwDesc <= 0 ) {
            navigate('/h5/bottle-recharge?appName=drift_bottle&userId=1123')
            return
        }
        setThrowDesc(0)
        setPopupVisible(true)
    }

    const onClickSalvageBottle = () => {
        if(salvageDesc <= 0) {
            navigate('/h5/bottle-recharge?appName=drift_bottle&userId=1123')
            return
        }
        Toast.show({
            icon: 'loading',
            content: '捞取中...',
            duration: 0
        })
        setSalvageDesc(0)
        setTimeout(() => {
            Toast.clear()
            Modal.show({
                actions: modalActions,
                closeOnAction: true,
                closeOnMaskClick: true,
                content: filterDesc === '只看女生' ? '有小哥哥聊会天吗？' : '这里有人嘛',
                onAction: onClickSalvageModalAction
            })
        }, 2000)
    }

    const onClickSalvageModalAction = (action) => {
        setPopupVisible(true)
    }

    const onClickSendButton = () => {
        setButtonLoading(true)
        setTimeout(() => {
            setButtonLoading(false)
            setInputValue('')
            Toast.show({
                icon: 'success',
                content: '发送成功',
            })
            setPopupVisible(false)
        }, 1000)
    }

    const getBeianDescByDomain = () => {
        const hostname = window.location.hostname
        if(hostname.indexOf('jeysin.top') >= 0) {
            return "浙ICP备2022033703号";
        } else if(hostname.indexOf('onewish.cn') >= 0) {
            return "苏ICP备2023030095号-1";
        } else if(hostname.indexOf('wittyai.cn') >= 0) {
            return "苏ICP备2024070402号-1";
        } else if(hostname.indexOf('bytesea.site') >= 0) {
            return "苏ICP备2023030095号-11";
        } else {
            return "浙ICP备2022033703号"
        }
    }

    const getTitleByDomain = () => {
        const hostname = window.location.hostname
        if(hostname.indexOf('jeysin.top') >= 0) {
            return "杭州燕景网络科技有限公司";
        } else if(hostname.indexOf('onewish.cn') >= 0) {
            return "南京瑜亮网络科技有限公司";
        } else if(hostname.indexOf('wittyai.cn') >= 0) {
            return "南京网景微讯网络科技有限公司";
        } else if(hostname.indexOf('bytesea.site') >= 0) {
            return "南京瑜亮网络科技有限公司";
        } else {
            return "杭州燕景网络科技有限公司"
        }
    }

    useEffect(() => {
        document.title = getTitleByDomain();
      }, []);
    return (
        <div className={styles.bottleHomepage}>
            <div className={styles.bottleHomepageBg}>
                <NoticeBar className={styles.noticeBar} content={noticeText} color='info' />
                <div className={styles.filterButton} onClick={onClickFilterButton}>
                    <Image className={styles.filterImageStyle} src={filterImage}/>
                    <div className={styles.filterDescStyle}>{filterDesc}</div>
                </div>
                <div className={styles.operatePlatform}/>
                <div className={styles.operateButtons}>
                    <div className={styles.operateButton} onClick={onClickThrowBottle}>
                        <div className={styles.operateButtonIcon}>
                            <Image className={styles.operateButtonIconImage} src={bottleImage}/>
                        </div>
                        <div className={styles.operateButtonIconText}>扔瓶子</div>
                        <div className={styles.redPointDesc}>{throwDesc}</div>
                    </div>
                    <div className={`${styles.operateButton} ${styles.operateButtonMarginLeft}`} onClick={onClickSalvageBottle}>
                        <div className={styles.operateButtonIcon}>
                            <Image className={styles.operateButtonIconImage} src={netImage}/>
                        </div>
                        <div className={styles.operateButtonIconText}>捞瓶子</div>
                        <div className={styles.redPointDesc}>{salvageDesc}</div>
                    </div>
                </div>
            </div>
            <BottleTabbar activeIndex={0} icons={icons} onClickIcon={onClickIcon}/>
            <div className={styles.beianDesc}>
                <a href="https://beian.miit.gov.cn">{getBeianDescByDomain()}</a>
            </div>
            <ActionSheet
                visible={visible}
                actions={actions}
                onAction={onActionSheetClick}
                onClose={() => setVisible(false)}
            />
            <Popup
              className={styles.popupStyle}
              visible={popupVisible}
              onMaskClick={() => {
                setPopupVisible(false)
              }}
              onClose={() => {
                setPopupVisible(false)
              }}
              bodyStyle={{ height: '40vh' }}
            >
                <TextArea
                    className={styles.textAreaStyle}
                    placeholder='请输入内容'
                    value={inputValue}
                    rows={10}
                    onChange={val => {
                        setInputValue(val)
                    }}
                />

                <Button className={styles.sendButton} loading={buttonLoading} block color='primary' size='large' onClick={onClickSendButton}>
                    发送
                </Button>
            </Popup>
        </div>
    )
}